/* Style Placeholders */
$placeholder-text-color: $light-text-color;

::-webkit-input-placeholder {
  color: $placeholder-text-color;
}

:-moz-placeholder { /* Firefox 18- */
  color: $placeholder-text-color;
}

::-moz-placeholder {  /* Firefox 19+ */
  color: $placeholder-text-color;
}

:-ms-input-placeholder {
  color: $placeholder-text-color;
}
