.footer {
  padding: .75rem 0;
  background: $body-bg;
  color: $light-text-color;
  font-size: .875rem;
  font-weight: $font-weight-light;
  text-align: center;

  img {
    width: 1.25rem;
    margin-right: .5rem;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.side-menu {
  padding-bottom: 3.125rem;
}

.search,
.app-detail {
  position: relative;
  min-height: 100vh;
  padding-bottom: 3.125rem;

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: $border;
  }
}
