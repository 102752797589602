@mixin make-container() {
  margin-right: auto;
  margin-left: auto;
  padding-right: ($grid-gutter-width / 2);
  padding-left:  ($grid-gutter-width / 2);
  width: 100%;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

.container {
  @include make-container();
  @include make-container-max-widths();
}

.container-fluid {
  width: 100%;
  @include make-container();
}

.row {
  margin-left: ($grid-gutter-width / -2);
  margin-right: ($grid-gutter-width / -2);

  @include clearfix();
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col {
    padding-right: 0;
    padding-left: 0;
  }
}

.col {
  float: left;
  min-height: 1px;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);

  

}
@include media-breakpoint-up(xs){
  .col {
    $i: 1;
    @while $i <= $grid-columns {
      $perc: unquote((100 / ($grid-columns / $i)) + "%");
      &.xs#{$i} {
        width: $perc;
      }
      $i: $i + 1;
    }
  }
  
}
@include media-breakpoint-up(sm){
  .col {
    $i: 1;
    @while $i <= $grid-columns {
      $perc: unquote((100 / ($grid-columns / $i)) + "%");
      &.s#{$i} {
        width: $perc;
      }
      $i: $i + 1;
    }
  }
  
}
@include media-breakpoint-up(md) {
  .col {
    $i: 1;
    &.m-4-half{
      width:37.8%;
    }
    &.m-1-and-half{
      width: 12.5%;
    }
    @while $i <= $grid-columns {
      $perc: unquote((100 / ($grid-columns / $i)) + "%");
      &.m#{$i} {
        width: $perc;
      }
      $i: $i + 1
    }
  }
}

@include media-breakpoint-up(lg) {
  .col {
    &.l-half-1{
      width:4%;
    }
    $i: 1;
    @while $i <= $grid-columns {
      $perc: unquote((100 / ($grid-columns / $i)) + "%");
      &.l#{$i} {
        width: $perc;
      }
      $i: $i + 1;
    }
  }
}

@include media-breakpoint-down(sm){
  .hide-on-small{
    display: none;
  }
}
@include media-breakpoint-down(md){
  .hide-on-md{
    display: none;
  }
}
// @include media-breakpoint-between(sm,md){
//   .hide-on-md{
//     display: none; 
//   }
// }
