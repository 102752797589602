@import "variables";
@import "mixins";
@import "reset";
@import "placeholder";
@import "grid";

@include media-breakpoint-only(lg) {
  html, body {
    font-size: 15px;
  }
}

@include media-breakpoint-only(md) {
  html, body {
    font-size: 14px;
  }
}

@include media-breakpoint-down(sm) {
  html, body {
    font-size: 13px;
  }
}

.clearfix { @include clearfix(); }
.text-center { text-align: center; }
.text-left   { text-align: left; }
.text-right  { text-align: right; }
.pull-right  { float: right; }
.pull-left   { float: left; }
.upper { text-transform: uppercase; }

.separator{
  display: inline-block;
  margin: 0 1rem;
  font-size: 0.8em;

  &:after {
    content: '';
    border-right: 1px solid $border-color;
  }
}

.boxed      { border        : $border; }
.bar-top    { border-bottom : $border; }
.bar-bottom { border-bottom : $border; }
.bar-left   { border-left   : $border; }
.bar-right  { border-right  : $border; }

h1, h2, h3, h4, h5, h6, strong {
  font-weight: $font-weight-bold;
}

h2 {
  margin-bottom: 0;
  a { display: block; }
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
  font-weight: $font-weight-normal;
  cursor: pointer;

  &:hover, &:active, &:focus {
    outline: none;
  }
}

input,
input:focus {
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: $font-weight-normal;
}

input[readonly] {
  background: rgba(192,192,192,.1);
}

.btn-link {
  color: $link-color;
  font-weight: $font-weight-semibold;
}
