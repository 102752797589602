// Grid
$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm:  600px,
  md:960px,
  lg: 1280px,
  xl:  1920px
) !default;

$container-max-widths: (
  sm: 700px,
  md: 900px,
  lg: 1180px,
  xl: 1440px
) !default;

// Colors
$white:               #FFF;
$body-bg:            #F6F8FA;

$primary-color:       #0C79B9;    // blue
$secondary-color:     #EF4C23;    // orange
$success-color:       #1ABC9C;    // green
$link-color:          $primary-color;
$link-hover-color:    darken($link-color, 15%);

$text-color:          #2E4057;
$medium-text-color:   #666;
$light-text-color:    #8492A6;
$lighter-text-color:  #BBB;
$dark-text-color:     #2E4057;
$border-color:        #E0E0E0;
$error-color:         red;

// Fonts
$font-family-base:    'proxima-nova', 'Gotham', sans-serif;

$font-size-base:      1rem; // Assumes the browser default, typically `16px`
$font-size-lg:        1.25rem;
$font-size-sm:        .875rem;
$line-height-base:    1.5;


$font-weight-light:      300;
$font-weight-normal:     400;
$font-weight-semibold:   600;
$font-weight-bold:       700;
$font-weight-base:       $font-weight-normal;
$dt-font-weight:         $font-weight-bold;

// Other sizes
$table-cell-padding:  .75rem;

// Utility variables
$border:              1px solid $border-color;

// Sprites

$sprite-image:  "../images/sprite.css-db4d6a29.svg";
$sprite-hover-image:        "../images/sprite.css-b9375c28.svg";
