.col-fifth {
  float: left;
  display: block;
  width: 48%;
  margin: 0 1%;
}

.metric-box {
  strong {
    font-size: 2rem;
    font-weight: $font-weight-normal;
  }

  span {
    font-size: 1.25rem;
    font-weight: $font-weight-light;
  }

  &.has-more {
    >div {
      @include clearfix();
    }

    small {
      font-size: .875rem;
      text-transform: none;

      &:first-child {
        float: left;
        width: 80%;
        @include ellipsis();
      }

      &:last-child {
        float: right;
        width: 20%;
        text-align: right;
      }
    }
  }
}

@media (max-width: 600px) {
  .col-fifth { width: 98%; }
}

@include media-breakpoint-down(md) {
  .col-fifth {
    margin-top: 1.25rem;
    padding: 2rem;
    color: $primary-color;
    background: $white;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);

    &:nth-child(4) {
      float: right;
    }
  }

  .metric-box {
    @include clearfix();
    i { display: none; }

    strong, span {
      line-height: 3rem;
    }

    span {
      float: left;
      width: 75%;
    }

    strong {
      float: right;
      width: 25%;
      font-size: 1.5rem;
      text-align: right;
    }
  }

  .metric-box.has-more {
    span {
      display: block;
      width: 100%;
    }

    small {
      color: $light-text-color;
      font-size: 1rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .metrics {
    text-align: center;
  }

  .col-fifth {
    min-width: 15rem;
    width: 17.6%;
    height: 17rem;
    margin: .5rem 1.25%; 

    &:last-child {
      margin-right: 0;
    }

    .metric-box {
      width: 100%;
      height: 100%;
      color: $primary-color;
      background: $white;
      box-shadow: 0 4px 8px rgba(64,64,64,.2);
    }
  }

  .metric-box {
    position: relative;
    strong, i, span {
      position: absolute;
      display: block;
      top: 50%;
      left: 10%;
      width: 80%;
    }

    strong {
      padding-bottom: .5rem;
      transform: translateY(-100%);
    }

    i {
      left: 20%;
      width: 60%;
      border-bottom: 1px solid $primary-color;
    }

    span {
      margin-top: 1rem;
    }

  }

  .metric-box.has-more {
    padding-top: 5rem;

    > span {
      top: 0; left: 0;
      width: 100%;
      margin: 0;
      padding: 1rem 2rem;
    }

    >div {
      font-weight: $font-weight-light;
      padding: .125rem 1.25rem;
      text-align: left;
    }
  }
}
