%svg-common {
  display: block;
	background: url($sprite-image) no-repeat;
}

.svg-Icon-Close {
	@extend %svg-common;
	background-position: 0 0;
	width: 20px;
	height: 20px;
}

.svg-Icon-Delete {
	@extend %svg-common;
	background-position: 0 6.622516556291391%;
	width: 13px;
	height: 20px;
}

.svg-Icon-Download {
	@extend %svg-common;
	background-position: 0 13.245033112582782%;
	width: 16px;
	height: 20px;
}

.svg-Icon-Edit {
	@extend %svg-common;
	background-position: 0 19.867549668874172%;
	width: 19px;
	height: 20px;
}

.svg-Icon-Folder-Menu {
	@extend %svg-common;
	background-position: 0 26.490066225165563%;
	width: 25px;
	height: 20px;
}

.svg-Icon-List {
	@extend %svg-common;
	background-position: 0 32.362459546925564%;
	width: 20px;
	height: 13px;
}

.svg-Icon-Merge {
	@extend %svg-common;
	background-position: 0 37.41721854304636%;
	width: 20px;
	height: 20px;
}

.svg-Icon-Page {
	@extend %svg-common;
	background-position: 0 44.18604651162791%;
	width: 23px;
	height: 21px;
}

.svg-Icon-Print {
	@extend %svg-common;
	background-position: 0 50.99337748344371%;
	width: 20px;
	height: 20px;
}

.svg-Icon-Rotate-Left {
	@extend %svg-common;
	background-position: 0 57.615894039735096%;
	width: 20px;
	height: 20px;
}

.svg-Icon-Rotate-Right {
	@extend %svg-common;
	background-position: 0 64.23841059602648%;
	width: 20px;
	height: 20px;
}

.svg-Icon-Search {
	@extend %svg-common;
	background-position: 0 70.62706270627062%;
	width: 19px;
	height: 19px;
}

.svg-Icon-Split {
	@extend %svg-common;
	background-position: 0 77.1523178807947%;
	width: 20px;
	height: 20px;
}

.svg-Icon-Thumbnails {
	@extend %svg-common;
	background-position: 0 83.7748344370861%;
	width: 20px;
	height: 20px;
}

.svg-Icon-Tick {
	@extend %svg-common;
	background-position: 0 88.63636363636364%;
	width: 20px;
	height: 14px;
}

.svg-Icon-View {
	@extend %svg-common;
	background-position: 0 93.48534201954398%;
	width: 28px;
	height: 15px;
}

.svg-Icon-Watchlist {
	@extend %svg-common;
	background-position: 0 100%;
	width: 14px;
	height: 20px;
}

.svg-Icon-Info {
	display:block;
	margin-bottom: -6px;
	background: url(../images/Icon-Info.svg);
	background-position: 0 100%;
	margin-bottom: -6px;
	width: 23px;
	height: 23px;
	
}
.icon-popover-wrapper{
	display: inline-block;
	position: relative;
	&:hover{
		.popover{
			display: block;
		}
	}
	.popover {
		display: none;
		position: absolute;
		top: 100%; right: 0;
		background: $white;
		box-shadow: 0 0 4px #ccc;
		padding-bottom: .5rem;
		z-index: 1100;
	
		a, span {
		  display: block;
		  padding: .5rem 1rem;
		  font-weight: 600;
		  text-align: right;
		  white-space: nowrap;
		}
	}
}