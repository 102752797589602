/* Loader */
$loading-fg:        $link-color;
$loading-height:    8px;

.loader,
.loader:before,
.loader:after {
  background: $loading-fg;
  animation: load1 1s infinite ease-in-out;
  width: 6px;
  height: 8px;
}

.loader {
  color: $loading-fg;
  text-indent: -9999em;
  position: absolute;
  font-size: 12px;
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -8px;
  animation-delay: -0.32s;
}

.loader:after {
  left: 8px;
}

@mixin load1-frames {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 8px;
  }

  40% {
    box-shadow:0 -6px;
    height: 16px;
  }
}

@-webkit-keyframes load1 {@include load1-frames;}
@keyframes load1 { @include load1-frames;}
