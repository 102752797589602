@import "variables";
@import "mixins";
@import "modal";
@import "loader";
@import "sprite";
@import "footer";
@import "metrics";

a:hover,
button:hover,
a.active,
button.active {
  span[class="svg-Icon-Info"]{
    background: url(../images/Icon-Info-Hover.svg) !important;
    background-repeat: no-repeat;
  }
  span[class^="svg-Icon"] {
    background-image: url($sprite-hover-image);
    background-repeat: no-repeat;
  }
}

a:disabled,
button:disabled {
  opacity: .5;
  cursor: default;

  span[class^="svg-Icon"] {
    background-image: url($sprite-image);
  }
}
@include media-breakpoint-down(sm) {
  span[class^="svg-Icon"] {
    transform: scale(.9);
  }
}

.loading {
  position: fixed;
  top: 40%; left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.home-header {
  padding: 1.25rem 0 1rem;
  background: $white;

  .container-fluid {
    max-width: 1840px;
    position: relative;
  }

  h2 a {
    width: 8%;

    @include media-breakpoint-only(lg) {
      width: 10%;
    }
    @include media-breakpoint-down(md) {
      width: 3rem;
    }
  }
}

.home-search {
  position: relative;
  text-align: center;
  background: $white;
  padding: 1rem 0 3rem;
  min-height: 260px;

  > .container {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 2rem;
  }

  .heading {
    color: $light-text-color;
    font-size: 3.5rem;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include media-breakpoint-down(lg) {
      font-size: 3rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 2.25rem;
    }
  }

  .search-form {
    position: relative;
    margin: 2.5rem auto;
    width: 100%;
    max-width: 750px;

    @include media-breakpoint-up(lg) {
      width: 80%;
    }

    input[type="text"] {
      width: 100%;
      font-size: 1.25rem;
      padding: .75rem 5rem .75rem 1.5rem;
    }

    .btn-search {
      width: 4.5rem;
    }
  }
}

.search-form {
  position: relative;

  input[type="text"] {
    // width: 16rem;
    width:9rem;
    text-align: left;
    font-size: .875rem;
    padding: .25rem 4.5rem .25rem .75rem;
    border: 1px solid #bbb;
  }

  .btn-search {
    position: absolute;
    top: 0; bottom: 0; right: 0;
    width: 2.25rem;
    background-color: $secondary-color;
    background-image: url(../images/Search-White.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.home-nav,
.home-sections {
  .container-fluid {
    max-width: 1600px;
  }
}

.home-nav {
  border-bottom: $border;

  ul {
    @include clearfix();
  }

  ul >li {
    display: inline-block;
    margin: 0 1.5rem;
    padding-top: .5rem;

    &:first-child {
      margin-left: 0;
    }

    @include media-breakpoint-down(md) {
      margin: 0 .5rem;
    }
  }

  ul >li >a {
    display: block;
    padding: 1rem 2rem;
    color: $light-text-color;
    font-size: 1.125rem;
    font-weight: $font-weight-light;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid transparent;
    transition: border .4s ease;

    @include media-breakpoint-down(md) {
      padding: .75rem 1.25rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  ul >li > a:hover {
    color: $link-color;
  }

  ul >li.active > a {
    color: $link-color;
    border-bottom: 2px solid $primary-color;
  }
}

// Make home sections navigation a scrollable component like Google
@include media-breakpoint-down(md) {
  .home-nav {
    overflow-y: hidden;
    overflow-x: scroll;

    ul {
      white-space: nowrap;
    }
  }
}


.home-sections {
  min-height: 20rem;
  padding: 2rem 0;
}

.static {
  width: 90%;
  max-width: 40rem;
  margin: 2rem auto;
  padding: 2.5rem;
  background: $white;
  text-align: center;

  h2 {
    margin-bottom: 2rem;
  }

  &.has-shadow {
    box-shadow: 0 4px 12px rgba(64,64,64,.1);
  }
}

$table-edge-padding: 2rem;
$order-box-height: 1.75rem;

table {
  width: 100%;
  background: $white;
  border: $border;
  font-size: .9375rem;

  thead >tr >th,
  tbody >tr >td {
    text-align: left;
    border-top: $border;
    &.xl, &.bw { word-break: break-word; }
    &.bw { min-width: 150px; }
    &:last-child { text-align: right; }

    // For split icon etc
    &.xs {
      width: 1.5rem;
      padding-right: 0;
      button { margin-left: 0; }
    }

    &.merge-col {
      text-align: center;
      input {
        zoom: 1.4;
      }
      span {
        display: inline-block;
        min-width: $order-box-height;
        height: $order-box-height;
        line-height: $order-box-height;
        border: $border;
      }
    }
  }

  tbody > tr:hover {
    cursor: pointer;
    background: lighten($primary-color, 60%);
  }

  thead >tr >th {
    color: $primary-color;
    padding: 1rem;
    font-size: .8125rem;
    font-weight: $font-weight-normal;
    white-space: nowrap;
    &:first-child { padding-left: $table-edge-padding; }
    &:last-child { padding-right: $table-edge-padding; }
  }

  tbody >tr >td {
    padding: .875rem 1rem;
    font-weight: $font-weight-light;

    &.search-filter {
      background: #f9f9f9;
      padding: .5rem .75rem;

      &.text-center {
        text-align: center;
      }

      button {
        padding: 0 .5rem;
        border: $border;
        color: $white;
        background: $primary-color;
      }
    }

    &:first-child {
      padding-left: $table-edge-padding;
    }

    &:last-child {
      white-space: nowrap;
      padding-right: $table-edge-padding;
    }

    a, button {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  input[type="text"] {
    width: 90%;
    height: 1.75rem;
    padding: 0 .5rem;
    font-size: .875rem;
    border: $border;
  }
}

.table-wrap {
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;
  table {
    min-width: 100%;
    border-width: 0 0 1px 0;
  }
}

@include media-breakpoint-down(md) {
  .table-wrap {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  table {
    width: auto;
    thead >tr >th,
    tbody >tr >td {
      padding-left: .25rem;
      padding-right: .25rem;

      &:first-child { padding-left: 1rem; }
      &:last-child { padding-right: 1rem; }
    }
  }
}

.recents {
  .table-wrap {
    margin-bottom: 1.5rem;
  }

  table.recent-documents {
    thead >tr >th,
    tbody >tr >td {
      @include ellipsis();
      &:last-child { text-align: left; }
    }
  }

}

.sortable table {
  thead >tr >th {
    cursor: pointer;

    span:after {
      display: inline-block;
      margin-left: .25rem;
      content: '\25BE';
    }

    span.asc:after,
    span.desc:after {
      color: $secondary-color;
    }

    span.desc:after {
      transform: rotate(180deg);
    }
  }
}

.audit-log table {
  thead >tr >th,
  tbody >tr >td {
    &:last-child { 
      text-align: left;
      white-space: normal;
      max-width:600px;
      word-wrap: break-word;
    }
    p {
      font-size: .875rem;
      margin-bottom: 0;
    }
  }
}

.svg-Icon-View {
  margin-bottom: 2px;
}

$avatar-size:      2.25rem;

.avatar {
  display: block;
  width: $avatar-size;
  height: $avatar-size;
  line-height: $avatar-size;
  border-radius: $avatar-size / 2;
  font-size: .875rem;
  color: $white;
  text-align: center;
  letter-spacing: 1px;
}

.profile {
  position: relative;
  display: inline-block;
  z-index: 1100;

  .avatar {
    background: $secondary-color;
  }

  .popover {
    display: none;
    position: absolute;
    top: 100%; right: 0;
    background: $white;
    box-shadow: 0 0 4px #ccc;
    padding-bottom: .5rem;
    z-index: 1100;

    a, span {
      display: block;
      padding: .5rem 1rem;
      font-weight: 600;
      text-align: right;
      white-space: nowrap;
    }

    a:hover,
    a:active {
      color: $white;
      background: $primary-color;
    }
  }

  &:hover .popover {
    display: block;
  }
}

$detail-header-height:  4.875rem;
$side-menu-width:       17.5rem;
$menu-transition:       .3s ease;

.app-detail,
.search {
  padding-top: $detail-header-height;
}

.app-detail .header,
.search-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: .5rem 0;
  background: $white;
  z-index: 1050;

  h2 {
    display: inline-block;
    margin-top: .125rem;
    margin-bottom: .375rem;
    text-align: center;
    vertical-align: middle;
    transform: scale(1.4);

    a {
      width: 2rem;
      color: $secondary-color;
      font-size: .625rem;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  .text-center img {
    width: 2.75rem;
    margin-top: 1rem;
    vertical-align: middle;
  }

  .profile {
    margin-top: .625rem;
  }
}

.search-results {
  padding: 2rem 0;

  h4,
  .btn-link {
    display: inline-block;
    font-size: 1rem;
    font-weight: $font-weight-semibold;
    line-height: 2;
  }

  h4 {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  h4 span {
    &:before, &:after { content: '"'; }
  }

  .btn-link {
    position: relative;
    padding-right: 1.125rem;
    .loader {
      top: 25%;
      right: 0;
      transform: scale(.6) translateZ(0);
    }
  }
}

.no-results {
  margin-top: 3rem;
  text-align: center;
  font-size: 1.125rem;
}

.load-more {
  margin: 2rem 0;
  text-align: center;

  >div {
    height: 2.4375rem;
    padding: .5rem 0;
  }

  button {
    min-width: 200px;
    padding: .5rem 0;
    color: $white;
    background: $primary-color;
    border-radius: .25rem;
    font-weight: $font-weight-semibold;
    opacity: .8;
    transition: all .3s ease-in;

    &:hover {
      opacity: 1;
    }
  }
}

$tbl-columns: 12;

.results-wrap {
  @for $i from 1 through $tbl-columns {
    &.hide-col-#{$i} {
      table th:nth-child(#{$i}),
      table td:nth-child(#{$i}) {
        display: none;
      }
    }
  }
}

.results-wrap .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  + .table-wrap,
  + .thumbs-grid {
    opacity: .5;
  }
}

.document-results {
  .results-wrap .loader {
    margin-left: $side-menu-width / 2;
  }
  .table-wrap {
    float: left;
    clear: left;
    width: 100%;
    margin: .75rem 0 .25rem;
    border-top: $border;
  }
}

.app-detail {
  a[download] { display: none; }

  .header {
    border-bottom: $border;

    h2,
    .app-id,
    .search-form {
      display: inline-block;
      margin-right: .40rem;
    }

    .search-form {
      margin-left: .25rem;
      input[type="text"] {
        padding-right: 1rem;
        background-color: #FAFAFA;
      }
      input, button {
        height: 32px;
      }
    }

    .btn-search {
      background-size: 18px 18px;
    }

    .app-id {
      vertical-align: middle;
      font-size: 1.125rem;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;

      a {
        display: block;
        font-size: .875rem;
      }
    }
  }

  .faux-bg {
    position: fixed;
    top: $detail-header-height;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: $white;
  }

  .side-menu {
    position: fixed;
    top: $detail-header-height;
    bottom: 0;
    left: 0;
    padding-left: 1.25rem;
    padding-right: .5rem;
    background-color: $body-bg;
    border-right: $border;
    overflow-y: scroll;
  }
}


.app-detail .side-menu {
  .sub-menu {
    margin-top: 2rem;
  }

  .sub-menu h4 {
    margin-bottom: 1rem;
    padding: 0 .5rem;
    color: $secondary-color;
    font-size: .8125rem;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
  }

  ul >li >a,
  ul >li >span {
    display: block;
    padding: .5rem;
    color: $medium-text-color;
    font-size: .875rem;
    line-height: 1.25;
    cursor: pointer;
  }

  ul >li.active >a,
  ul >li.active >span {
    color: $white;
    background: $link-color;
    font-weight: $font-weight-bold;
  }

  ul >li.zero >a,
  ul >li >span {
    color: #aaa;
  }

  ul >li.active.zero >a,
  ul >li.active.zero >span {
    color: $white;
    background: $lighter-text-color;
  }

  .close {
    display: none;
    position: absolute;
    top: .5rem;
    right: .5rem;
    transform: scale(.7);

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .text-block {
    width: 80% !important;
    margin-top: 1.75rem;
    .text-row {
      height: .75rem !important;
      margin-left: .5rem;
    }
  }
}


.app-detail .main {

  .table-wrap {
    border-left: 0;
    border-right: 0;
  }

  table >thead >tr >th {
    border-top: 0;
  }

  table >thead >tr >th,
  table >tbody >tr >td {
    padding-top: .75rem;
    padding-bottom: .75rem;

    &:first-child {
      padding-left: 0;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
    }


    &:nth-last-child(2) { // IW Classified
      width: 40px;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      span { display: inline-block; }
    }

    &:last-child  {
      max-width: 140px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  table >thead >tr >th:last-child {
    text-align: center;
  }
}

.app-detail .main table.has-thumbs,
.search-results table.has-thumbs {
  thead >tr >th,
  tbody >tr >td {
    &:first-child {
      padding-left: 10px;
    }
    img {
      border: $border;
    }
    &.xl { display: none; }
  }
}

.thumbs-grid {
  @include clearfix();
  margin-left: -1 * ($grid-gutter-width / 2);
  margin-right: -1 * ($grid-gutter-width / 2);

  .item-wrap {
    float: left;
    width: 50%;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;

    &:nth-child(2n+1) {
      clear: left;
    }
  }

  .item {
    @include clearfix();
    margin-top: 1rem;
    padding: 1rem;
    background: $white;
    box-shadow: 0 2px 4px rgba(64,64,64,.1);

    &:hover {
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(64,64,64,.2);
    }

    img { border: 1px solid #ccc; }

    .thumb {
      float: left;
      width: 30%;
    }

    .details {
      float: left;
      width: 70%;
      padding-left: 1rem;

      span, strong {
        display: block;
      }

      strong {
        font-weight: $font-weight-semibold;
        word-break: break-word;
      }
    }

    button {
      margin-top: 1rem;
      margin-right: 1rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .thumbs-grid {
    .item-wrap {
      float: none;
      width: 100%;
    }
  }
}

.app-detail {
  .side-menu {
    left: 0;
    width: $side-menu-width;
    transition: left $menu-transition;
    z-index: 1051;
  }
  .faux-bg   {
    left: $side-menu-width;
  }
  .main,
  .document-detail {
    margin-left: $side-menu-width;
  }

  .main {
    padding: 1.5rem;
  }

  .__react_component_tooltip {
    z-index: 1060;
  }
}

@include media-breakpoint-down(lg) {
  .app-detail .main {
    table >thead >tr >th,
    table >tbody >tr >td {
      &.xl { display: none; }
    }
  }
}

@include media-breakpoint-down(md) {

  .app-detail {
    .main {
      padding: 1rem 0;
    }

    .main,
    .document-detail {
      margin-left: 0;
    }
  }

  .app-detail .header {
    h2,
    .search-form {
      margin-right: 0;
    }

    .app-id,
    .btn-action {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .search-form {
      margin-left: .5rem;
    }

    .search-form input[type="text"] {
      width: 10rem;
    }
  }

  .app-detail:not(.menu-opened) {
    .side-menu { left: $side-menu-width * -1; }
    .faux-bg   { left: 0; }
  }

  .app-detail.menu-opened {
    .side-menu { width: 20rem; }
  }
}
@include media-breakpoint-up(xl){
  .app-detail .header {
    .search-form input[type="text"] {
      width: 13rem;
    }
  }
}
.main-header {
  margin-bottom: .75rem;
  @include clearfix();
  text-align: center;

  h4 {
    float: left;
    margin-bottom: 0;
    font-size: 1.375rem;
    line-height: 2.25rem;
  }
}

.main-header,
.document-header {
  .hamburger {
    float: left;
    margin-top: .25rem;
    margin-right: .625rem;
    transition: all .5s ease;
  }
}

.main-header,
.with-actions {
  position:relative;

  .actions {
    float: right;
    text-align: right;
  }

  .btn-icon,
  .show-cols{
    display: none;
    width: 2rem;
    height: 2rem;
    margin-left: .75rem;
    border: 1px solid $border-color;
    vertical-align: middle;

    &:hover,
    &.active {
      background: $link-color;
    }

    span {
      margin: 0 auto;  // align center
      transform: scale(.75);
    }
  }

  .btn-upload,
  .btn-merge {
    width: auto;
    height: 2rem;
    line-height: 2rem;
    padding: 0 1.5rem;
    vertical-align: middle;
  }

  .btn-merge {
    margin-right: 1rem;
  }

  .show-cols:hover,
  .show-cols.active {
    color: $white;
  }
}

.column-names {
  position: absolute;
  top: 0;
  right: 6rem;
  background: $white;
  border: $border;
  text-align: left;
  span {
    display: block;
    padding: .5rem 1rem;
    cursor: pointer;

    &:hover {
      background: lighten($primary-color, 60%);
    }

    i {
      display: inline-block;
      width: 1rem;
      margin-right: .5rem;
      font-style: normal;
      line-height: 1;
    }
  }

  button {
    display: block;
    margin: .625rem auto;
  }
}

@include media-breakpoint-up(lg) {
  .main-header,
  .document-header {
    .hamburger { display: none; }
  }

  .main-header,
  .with-actions {
    .btn-icon,
    .show-cols {
      display: inline-block;
    }
  }
}

.list-filters {
  margin-bottom: .75rem;
  @include clearfix();

  .Select {
    float: left;
    width: 13.25rem;
    margin-right: .75rem;
    font-size: .875rem;
    .Select-arrow { top: 2px; }
    &.is-open .Select-arrow { top: -1px; }

    &.doctype-filter {
      width: 15.5rem;
    }
  }

  .Select-control {
    height: 32px;
    border-radius: 0;
    .Select-value {
      padding-right: 3rem;
    }
  }

  .Select-input,
  .Select-clear,
  .Select-placeholder,
  .Select-control .Select-value {
    height: 30px;
    line-height: 30px;
  }
}

.tick,
.btn-load,
.btn-action {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.tick,
.btn-load {
  height: 2.25rem;
  line-height: 2.25rem;
}

.tick {
  font-size: 1.75rem;
  color: $primary-color;
}

.btn-load {
  position: absolute;
  right: 0;
  width: 6rem;  
  height: 1.625rem;

  .loader {
    top: .5rem;
    left: 50%;
  }
}

.btn-primary {
  display: inline-block;
  padding: .5rem 2rem;
  color: $white;
  background: $link-color;
  font-size: .875rem;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover, &:active, &:focus {
    color: $white;
    background: darken($link-color, 5%);
  }

  &[disabled] {
    cursor: not-allowed;
    background: $lighter-text-color;
  }
}

.document-detail {

  .container-fluid {
    padding: 0;
  }

  .document-header {
    line-height: 2rem;
    padding: .75rem 1.5rem;
    border-bottom: $border;

    h4 span {
      display: inline-block;
      margin-right: 1rem;
      vertical-align: middle;
    }
  }

  .document-preview {
    border-right: $border;
  }
}

.document-header {
  background-color: $body-bg;
  z-index: 1010;

  h4 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: $font-weight-normal;
  }

  .actions {
    position: relative;
    padding-right: 0;
    text-align: right;
  }

  .split-form {
    position: relative;

    form > * {
      margin-left: .5rem;
      margin-right: 0;
    }

    span,
    input {
      font-size: .875rem;
    }

    input[type="text"] {
      width: 5rem;
      height: 2.375rem;
      padding-left: .5rem;
      border: $border;
    }
  }

  .float-error {
    width: 100%;
    text-align: center;
    top: 100%;
    padding-top: .5rem;
    color: red;
    font-size: .875rem;
    line-height: 1rem;
  }

  .actions .btn-action {
    margin-left: .5rem;
    padding: 0 .5rem;
  }

  .btn-primary {
    margin-right: .5rem;
    padding: .125rem 1rem;
  }

  .tick,
  .btn-load {
    width: 5rem;
  }
}

@include media-breakpoint-down(sm) {
  .document-header {
    position: relative;

    h4 {
      padding-right: 1.5rem;
      line-height: 1.75;
    }

    .actions .btn-action {
      margin-left: 0;
    }

    .scrollable {
      overflow-y: hidden;
      overflow-x: scroll;
      padding-left: 0;
      padding-right: 0;

      h4 {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .document-header h4 {
    @include ellipsis();
    line-height: 2;
  }
}
.document-preview {
  background-color: #f9f9f9;
  overflow: hidden;
  z-index: 1005;

  &.reordering {
    background: $white;
  }

  iframe {
    width: 100%;
    margin-top: -1px;
    border: none;
    outline: none;
  }

  .preview-box {
    position: relative;
    background: #fcfcfc;
  }

  .preview-status {
    position: absolute;
    top: 40%; left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.25rem;

    p {
      margin-bottom: .5rem;
    }

    .btn-load {
      position: static;
      display: block;
      margin: 0 auto;
      width: 20px;
      transform: scale(.8);
    }
  }

  .page-preview {
    border-left: 1px solid $border-color;

    > div {
      padding: 40px;
      min-height: 600px;
      text-align: center;
    }

    img {
      border: 1px solid $light-text-color;
    }
  }

  .pdf-thumbs {
    height: 100%;
    overflow-y: auto;
  }

  .pdf-thumb {
    text-align: center;
    padding: 20px;

    img {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .loading-page {
    position: relative;
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 100%;

    .loader {
      position: absolute;
      top: 50%; left: 50%;
    }
  }

  .pages-toolbar {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 40px;
    background: $white;
    text-align: right;
    button {
      width: 40px;
      height: 40px;
    }
  }

  .small-thumbs {
    position: relative;
    padding-top: 60px;
    background: $border-color;
    .pdf-thumb { 
      float: left;
      width: 33.333%;
      height: 0;
      padding: 10px;
      padding-bottom: 42%;
      cursor: pointer;
      img {
        border: 2px solid transparent;
        
      }

      &:hover img {
        border: 2px solid $light-text-color;
      }

      &[data-status="active"] img {
        border: 2px solid $link-color;
      }
    }
  }
}
.center-align-smallthumbs{
  height:260px;
  line-height: 260px;
  white-space: nowrap;
}
.document-metadata {
  padding-bottom: 2rem;

  h6, .control {
    padding: 1rem 2rem;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  h6 {
    font-size: 1rem;
    font-weight: $font-weight-normal;
    border-bottom: $border;
  }

  label {
    display: block;
    margin-bottom: .5rem;
    color: $light-text-color;
    font-size: .875rem;
  }

  .control {
    position: relative;

    > input {
      display: block;
      width: 100%;
      padding: .3125rem 1rem;
      border: 1px solid #ccc;
      border-radius: 2px;
    }

    .tooltip {
      position: absolute;
      display: inline-block;
      top: 100%; left: 2rem;
      margin-top: -.5rem;
      padding: 4px;
      color: $white;
      background: $primary-color;
    }

    a {
      display: block;
    }
  }

  .control.has-error {
    label, .error { color: $error-color; }
    > input,
    .Select-control {
      border-color: $error-color;
    }
  }

  .error {
    margin-top: .5rem;
    color: $error-color;
    font-size: .875em;
  }
}

.upload-area {
  margin-bottom: 1rem;

  h4 {
    margin-bottom: .625rem;
    font-size: 1rem;
    font-weight: $font-weight-semibold;
  }

  .file-error {
    position: relative;
    margin-bottom: .625rem;
    padding: .625rem 1rem;
    background: #FAFAFA;
    border: 1px solid #e5e5e5;
    font-size: .875rem;
    @include clearfix();

    p {
      display: inline;
      line-height: 1;
      &:nth-child(2) {
        margin-right: 2rem;
      }
    }

    button {
      float: right;
      color: $primary-color;
      margin-left: 1rem;
      &:hover {
        color: darken($primary-color, 5%);
      }
    }
  }

  .uploaded {
    margin-top: 1.5rem;
  }
}

.dropzone {
  width: 100%;
  padding: 3rem 1rem;
  background-color: #F3F8FB;
  border: 1px dashed $border-color;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  button {
    margin-bottom: .5rem;
  }
}

.upload-area .files {
  width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;

  .document-form {
    &:first-child {
      color: $primary-color;
    }
  }
}

.upload-area {
  .upload-header {
    color: $primary-color;
    font-size: .875rem;
    @include clearfix();
  }

  .upload-header > div,
  .document-form > .control {
    float: left;
    width: 17%;
    padding: .5rem;
    vertical-align: middle;

    &:first-child {
      width: 18%;
      padding-left: 0;
    }

    &:nth-child(2) {
      width: 14%;
    }
  }

  .document-form {
    .actions,
    .doc-progress {
      float: left;
      width: 15%;
      padding: .5rem 0;
      text-align: right;
    }
  }

  @include media-breakpoint-up(lg) {
    .application-upload {
      .upload-header > div,
      .document-form > .control {
        width: 20%;
        &:first-child { width: 20%; }
        &.refid-control { display: none; }
      }

      .document-form .actions,
      .document-form .doc-progress {
        width: 20%;
      }
    }
  }
}

.inline-tooltip {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin: 0 .5rem;
  line-height: 1rem;
  cursor: pointer;
  border-radius: .5rem;
  border: 1px solid $link-color;
}

.pagination-container {
  float: left;
  width: 100%;
  text-align: center;

  &.position-bottom {
    margin-top: .5rem;
  }
}

.pagination li {
  display: inline-block;

  a {
    display: block;
    cursor: pointer;
    padding: .5rem;
    &:active, &:focus {
      border: none;
      outline: none;
    }
  }

  &.active a,
  &.break a {
    color: $light-text-color;
  }

  &.active a {
    font-weight: bold;
  }
}

.upload-area .document-form {
  position: relative;
  font-size: .875rem;
  @include clearfix();

  .has-error {
    input[type="text"],
    .Select-control {
      border: 1px solid $error-color;
    }
  }

  .doc-save {
    color: $link-color;
    border: 1px solid $link-color;
    border-radius: 4px;

    &:hover {
      color: $white;
      background: $link-color;
    }

    &[disabled] {
      color: $white;
      background: #ccc;
      border: none;
    }
  }

  label {
    display: none;
  }

  input[type="text"] {
    width: 98%;
    height: 36px;
    padding-left: 1rem;
    border: $border;
  }

  .Select-control {
    border-radius: 0;
    border-color: $border-color;
  }
  .Select-clear-zone,
  .Select-arrow-zone {
    padding-top: 4px;
  }
  .Select-clear   { color: #ccc; }
  .Select-arrow   { border-color: #ccc transparent transparent; }
  .Select-value   { right: 1.75rem; }

  .doc-progress {
    position: relative;
    color: $link-color;
    font-size: .875rem;
    text-transform: uppercase;

    > span {
      position: absolute;
      top: 1.125rem;
      right: 5rem;
      font-weight: $font-weight-semibold;
    }
  }


  .btn-primary {
    padding: 6px 1rem;
  }

  .btn-cancel {
    margin-left: .5rem;
    color: $link-color;
    font-size: .875rem;
    line-height: 1.25rem;
    &:hover {
      color: #efa5a5;
    }
  }

  .btn-load .loader {
    top: .875rem;
  }

  .overlay {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(64,64,64,.8);
    vertical-align: middle;
  }

  .overlay .doc-error {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    color: $white;
    white-space: nowrap;

    button {
      color: #f5f5f5;
      text-decoration: underline;
    }
  }

  .double-field,
  .dynamic-field {
    label {
      display: block;
      margin: .75rem 0 0;
      color: $light-text-color;
    }
  }

  .dynamic-field label { color: $light-text-color; }
  .double-field label  { color: $link-color; }
}

@include media-breakpoint-down(md) {
  .upload-area {
    .upload-header {
      display: none;
    }

    .document-form {
      margin-top: 1rem;
      background: #fafafa;
      border: 1px solid #e5e5e5;

      label {
        display: block;
      }

      > .control {
        float: none;
        width: 100%;
        padding: .5rem 1rem;

        &:first-child,
        &:nth-child(2),
        &:last-child {
          width: 100%;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .actions {
        float: none;
        width: 100%;
        padding: 1rem;
        text-align: left;
      }
    }
  }
}
.header-label{
  color: $primary-color;
  font-size: .875rem;
}
.app-detail-value{
  font-size: .875rem;
}
.app-detail-center-align{
  padding-top: .30rem;
}

.close-out{
  color: $error-color;
  vertical-align: middle;
}
